import { Router } from '@reach/router';
import Caller from './pages/Caller';
import './App.scss';

const App = () => {

  return (
    <div>
      <Router>
        <Caller path="/" />
      </Router>
    </div>
  );
}

export default App;
