import React, { useState } from "react";
import Fab from "@material-ui/core/Fab";
import SettingsIcon from "@material-ui/icons/Settings";
import SettingDialog from "../components/SettingDialog";

const Header = ({ settings, onUpdate }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    onUpdate(value);
  };

  return (
    <div className="Header">
      <img className="Logo" src="/Banner logo.png" alt="Banner logo" />
      <Fab variant="extended" className="Settings" onClick={handleClickOpen}>
        Settings
        <SettingsIcon fontSize="large" />
      </Fab>
      <SettingDialog maxBalls={settings.maxBalls} numberType={settings.numberType} open={open} onClose={handleClose} />
    </div>
  );
};

export default Header;
