import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';

const Tools = ({gameId, onNewNumber, onNewGame}) => {

  const handleNewNumberClick = () => {
    onNewNumber();
  }

  const handleNewGameClick = () => {
    onNewGame();
  }

  return (
    <div className="Tools">
      { gameId ? 
        <div className="Game-name">{gameId}</div>
        : null 
      }
      <Tooltip title="New Number">
        <div className="Next Button" onClick={handleNewNumberClick}>
          <AddIcon fontSize="large"/>
          <span></span>
        </div>
      </Tooltip>
      <div className="New Button" onClick={handleNewGameClick}>New Game</div>
    </div>
  );
}

export default Tools;