import React, { useState } from 'react';
import {
Grid,
DialogTitle,
Dialog,
TextField,
DialogContent,
DialogActions,
Button,
FormLabel,
RadioGroup,
Radio,
FormControlLabel
}from '@material-ui/core';

const SettingDialog = (props) => {
  const { onClose, maxBalls, numberType, open } = props;

  const [localMaxBalls, setLocalMaxBalls] = useState(maxBalls);
  const [localNumberType, setLocalNumberType] = useState(numberType);

  const handleClose = () => {
    onClose({maxBalls: localMaxBalls, numberType: localNumberType});
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="setting-dialog-title" open={open}>
      <DialogTitle id="setting-dialog-title">Settings</DialogTitle>
      <DialogContent>
      <Grid
        container
        direction="column"
        justify="flex-start"
        alignItems="flex-start"
        spacing={3}
      >
        <Grid item>
          <TextField
            id="max-ball-count"
            label="Number of balls per game"
            defaultValue={localMaxBalls}
            helperText="Sets how many balls are used"
            variant="outlined"
            type="number"
            onChange={(event) => { setLocalMaxBalls(Number(event.target.value))}}
          />
        </Grid>
        <Grid item>
          <FormLabel component="legend">Number board type</FormLabel>
          <RadioGroup aria-label="numberType" name="numberType" value={localNumberType} onChange={(event) => { setLocalNumberType(event.target.value)}}>
            <FormControlLabel value="Fixed" control={<Radio color="primary"/>} label="Fixed" />
            <FormControlLabel value="Dynamic" control={<Radio color="primary"/>} label="Dynamic" />
          </RadioGroup>
        </Grid>
      </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SettingDialog;