import React from 'react';
import {
  Grid,
  Paper
} from '@material-ui/core'

const UsedNumbers = ({numbers}) => {

  const renderNumbers = (numbers) => 
    numbers.map((n,i) => (
    <Grid item xs={2} key={`${n}-${i}`} className="FixedNumber">
      <Paper className="Paper">
        {n}
      </Paper>
    </Grid>
  ))

  return (
    <Grid container spacing={2} className="UsedNumbers grid">
      <Grid item xs={12}>
        <h2 className="Title">Used Numbers</h2>
      </Grid>
      {renderNumbers(numbers).reverse()}
    </Grid>
  );
}

export default UsedNumbers;
