// provide the call with there own little app that generates and displays random number
// it also displays the list of all ready pulled numbers
import React, { useState } from 'react';
import NumberContainer from '../components/NumberContainer';
import Header from '../components/Header';
import Tools  from '../components/Tools';
import {
  Container,
  Grid
} from '@material-ui/core'

import defaultOptions from '../config/defaults.js';

const Caller = props => {
  const [currentNumber, setCurrentNumber] = useState(null);
  const [usedNumbers, setUsedNumbers] = useState([]);
  const [maxNumber, setMaxNumber] = useState(defaultOptions.maxNumberCount)
  const [numberBoardType, setNumberBoardType] = useState(defaultOptions.numberBoardType)
  const [tumbler, setTumbler] = useState(Array.from({length: maxNumber}, (_, i) => i + 1))

  const pullNumberFromTumbler = () => {
    let copyOfTumbler = [...tumbler];
    let index = Math.floor(Math.random() * copyOfTumbler.length);
    let newNumber = copyOfTumbler.splice(index, 1)[0];
    setTumbler(copyOfTumbler);

    return newNumber;
  }

  const handleNewNumber = () => {
    if(currentNumber) {
      let currentUsedNumbers = [...usedNumbers];
      currentUsedNumbers.push(currentNumber);
      setUsedNumbers(currentUsedNumbers);
    }
    
    setCurrentNumber(pullNumberFromTumbler());
  }

  const handleNewGame = () => {
    setCurrentNumber(null)
    setUsedNumbers([])
    setTumbler(Array.from({length: maxNumber}, (_, i) => i + 1))
  }

  const handleOnSettingUpdate = (value) => {
    setMaxNumber(value.maxBalls)
    setNumberBoardType(value.numberType)
  }

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Header settings={{maxBalls: maxNumber, numberType: numberBoardType}} onUpdate={handleOnSettingUpdate}/>
        </Grid>
        <Grid item xs={12}>
          <NumberContainer 
            currentNumber={currentNumber} 
            usedNumbers={usedNumbers} 
            options={{fixedNumbers: numberBoardType === "Fixed", maxNumberCount: maxNumber}}
          />
        </Grid>
        <Grid item className="Tools-row" xs={12}>
          <Tools onNewNumber={handleNewNumber} onNewGame={handleNewGame} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default Caller;