import React from 'react';
import CurrentNumber from './CurrentNumber'
import UsedNumbers from './UsedNumbers'
import {
  Container,
  Grid,
  Paper
} from '@material-ui/core'
import FixedUsedNumberBoard from './FixedUsedNumberBoard';

import defaultOptions from '../config/defaults.js';

const NumberContainer = ({currentNumber, usedNumbers, options = null}) => (
  <Container>
    <Grid 
      container 
      direction="row"
      justify="space-between"
      alignItems="center"
      spacing={3} 
      className="NumberContainer"
    >
      <Grid item xs={4} md={3}>
        <Paper elevation={3}>
          {
            options?.fixedNumbers 
              ? <FixedUsedNumberBoard 
                  numbers={usedNumbers} 
                  maxCount={options.maxNumberCount ?? defaultOptions.maxNumberCount} 
                /> 
              : <UsedNumbers numbers={usedNumbers} />
          }
        </Paper>
      </Grid>
      <Grid item xs={8} md={9}>
        <Paper className="Paper CurrentNumber" elevation={3}>
          <CurrentNumber  number={currentNumber} />
        </Paper>
      </Grid>
    </Grid>
  </Container>
);


export default NumberContainer;