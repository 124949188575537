import React from 'react';
import {
  Grid,
  Paper
} from '@material-ui/core'

const FixedUsedNumberBoard = ({numbers, maxCount}) => {

  const renderNumber = (number, isActive = false) => (
    <Grid item xs={2} key={`number-${number}`} className="FixedNumber">
      <Paper className="Paper">
        {
          isActive 
          ? <div className="Number-Active">{number}</div>
          : number
        }
      </Paper>
    </Grid>
  )

  const renderNumbers = (numbers, maxCount) => {
    const elements = [];
    for(var i = 1; i <= maxCount; i++) {
      if(numbers.includes(i)) elements.push(renderNumber(i, true))
      else elements.push(renderNumber(i))
    }
    return elements;
  }

  return (
    <Grid container spacing={3} className="FixedUsedNumberBoard grid">
      <Grid item xs={12}>
        <h2 className="Title">Used Numbers</h2>
      </Grid>
      {renderNumbers(numbers, maxCount)}
    </Grid>
  );
}

export default FixedUsedNumberBoard;